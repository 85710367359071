//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.pad-margin-0 {
  padding: 0;
  margin: 0;
}

.pad-0 {
  padding: 0;
}

.center-text {
  text-align: center;
}
